import styled from "styled-components";
import { Dialog, Flex, mediaQueries } from "@mightybot/web-ui";

export const DialogContent = styled(Flex)`
	flex-direction: column;
	gap: 20px;
	overflow: hidden;
	flex: 1;

	${mediaQueries.laptop} {
		padding: 18px;
		gap: 18px;
		overflow: auto;
	}

	${mediaQueries.tablet} {
		padding: 16px;
		gap: 16px;
	}

	${mediaQueries.mobile} {
		padding: 12px;
		gap: 12px;
	}
`;

export const DialogRoot = styled(Dialog.Root)`
	.rt-BaseDialogScrollPadding,
	.rt-DialogScrollPadding {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px;
		min-height: 100vh;
	}

	.rt-DialogContent {
		margin: 0 auto;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	${mediaQueries.mobile} {
		.rt-BaseDialogScrollPadding,
		.rt-DialogScrollPadding {
			padding: 0;
		}
	}
`;

export const ContentWrapper = styled(Flex)`
	width: 100%;
	flex: 1;

	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const ClipFormSection = styled(Flex)`
	flex-direction: column;
	gap: 16px;
	min-width: 0;
	padding: 20px;
	border-right: 1px solid var(--gray-5);
	flex: 1;

	${mediaQueries.laptop} {
		padding: 18px;
	}

	${mediaQueries.tablet} {
		padding: 16px;
		border-right: none;
		border-bottom: 1px solid var(--gray-5);
	}

	${mediaQueries.mobile} {
		padding: 12px;
		gap: 12px;
	}
`;

export const ClipDetailsSection = styled(Flex)`
	flex-direction: column;
	width: 400px;
	padding: 20px;
	overflow-y: auto;

	${mediaQueries.laptop} {
		width: 360px;
		padding: 18px;
	}

	${mediaQueries.tablet} {
		width: 100%;
		padding: 16px;
	}

	${mediaQueries.mobile} {
		padding: 12px;
	}
`;

export const TranscriptContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	max-height: 200px;

	${mediaQueries.laptop} {
		min-height: 180px;
	}

	${mediaQueries.tablet} {
		min-height: 150px;
		padding: 14px;
	}

	${mediaQueries.mobile} {
		min-height: 120px;
		padding: 12px;
	}
`;
