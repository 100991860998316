import React, { useCallback, useState, useRef } from "react";
import { cn } from "../../utils/classNames";
import { Icon, IconButton, useMediaQuery } from "@mightybot/web-ui";
import { CaretLeft, X } from "@phosphor-icons/react";
import { motion, AnimatePresence } from "framer-motion";
import {
	useGetMeetingVideoQuery,
	useGetMeetingTranscript,
} from "@mightybot/core";
import { VideoJsPlayer } from "../VideoPlayer";
import TranscriptView from "../VideoPlayer/TranscriptView";
import { useParams } from "react-router-dom";
import { isGoogleSignedUrlExpired } from "../../utils/helpers";
import { breakpoints } from "@mightybot/web-ui";

export const ToggleButton = ({
	onClick,
	isExpanded,
}: {
	onClick: () => void;
	isExpanded: boolean;
}) => (
	<IconButton
		onClick={onClick}
		radius="full"
		className={cn(
			"bg-white cursor-pointer border-[#E0E0E0] border border-solid absolute top-3 right-5",
		)}
	>
		<CaretLeft
			width="16"
			height="16"
			className={cn(
				"text-[#333333] transition-transform duration-300",
				isExpanded ? "rotate-180" : "rotate-0",
			)}
		/>
	</IconButton>
);

interface MeetingTranscriptSidebarProps {
	isOpen: boolean;
	className?: string;
}

const MeetingTranscriptSidebar: React.FC<MeetingTranscriptSidebarProps> = ({
	isOpen,
	className,
}) => {
	const meetingId = useParams().meetingId;
	const {
		data,
		isLoading: isLoadingVideo,
		refetch,
	} = useGetMeetingVideoQuery({
		meeting_id: meetingId ?? "",
	});
	const { data: transcriptData, isLoading } = useGetMeetingTranscript({
		meeting_id: meetingId ?? "",
	});
	const playerRef = useRef<any>(null);
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [isExpanded, setIsExpanded] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);

	const sidebarWidth = isTablet ? "100%" : isExpanded ? 564 : 350;

	const checkAndRefreshUrl = useCallback(() => {
		if (!data?.video_url) return;

		try {
			const isExpired = isGoogleSignedUrlExpired(data.video_url);
			if (isExpired) {
				refetch();
			}
		} catch (error) {
			console.error("Error checking URL expiration:", error);
		}
	}, [data?.video_url, refetch]);

	const handleTimeUpdate = useCallback(
		(time: number) => {
			setCurrentTime(time);
			checkAndRefreshUrl();
		},
		[checkAndRefreshUrl],
	);

	const handleExpand = useCallback(() => {
		setIsExpanded(!isExpanded);
	}, [isExpanded]);

	const handleSeek = useCallback((time: number) => {
		if (playerRef.current) {
			playerRef.current.currentTime(time);
		}
	}, []);

	const videoJsOptions = {
		sources: [
			{
				src: data?.video_url,
				type: "video/mp4",
			},
		],
		controls: true,
		fluid: true,
		preload: "auto",
		controlBar: {
			volumePanel: {
				inline: false,
			},
		},
	};

	return (
		<AnimatePresence mode="sync" initial={false}>
			{isOpen && (
				<motion.div
					id="sidebar"
					key="sidebar"
					initial={isTablet ? { x: "100%" } : { width: 0, opacity: 0 }}
					animate={isTablet ? { x: 0 } : { width: sidebarWidth, opacity: 1 }}
					exit={isTablet ? { x: "100%" } : { width: 0, opacity: 0 }}
					transition={{
						duration: 0.3,
						ease: "easeInOut",
						opacity: { duration: 0.2 },
					}}
					style={{ zIndex: 1000 }}
					className={cn(
						"bg-[var(--mb-color-light-gray-3)] overflow-hidden",
						!isTablet && "min-w-[350px] flex-shrink-0 h-full shadow-lg",
						isTablet && "h-[100%] w-full",
						className,
					)}
				>
					<div className={cn("h-full flex flex-col")}>
						<div className={cn("p-3 flex-shrink-0")}>
							<div>
								<VideoJsPlayer
									isLoading={isLoadingVideo}
									ref={playerRef}
									options={videoJsOptions}
									setCurrentTime={handleTimeUpdate}
									onExpand={handleExpand}
									src={data?.video_url}
									onError={checkAndRefreshUrl}
								/>
							</div>
						</div>
						<div className="flex items-center justify-between px-3 border-b border-gray-200">
							<div className="flex items-center gap-2">
								<Icon.Article className="h-5 w-5" />
								<h2 className="font-medium text-base">Transcript</h2>
							</div>
						</div>
						<div
							className={cn(
								"flex-1 overflow-hidden px-3 mb-[100px]",
								isTablet && "mb-[20px]",
							)}
						>
							<TranscriptView currentTime={currentTime} onSeek={handleSeek} />
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default MeetingTranscriptSidebar;
