import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Flex, Text, Icon, Skeleton, Tabs, Button } from "@mightybot/web-ui";
import { useSnippets, useMe } from "@mightybot/core";
import VideoJS from "../VideoPlayer/VideoJsPlayer";
import TranscriptView from "../VideoPlayer/TranscriptView";
import {
	Container,
	ContentWrapper,
	HeaderSection,
	MetadataSection,
	VideoSection,
	DetailsSection,
	MainContent,
	VideoContainer,
} from "./styled";
import UnauthenticatedDialog from "../UnauthenticatedDialog";
import videojs from "video.js";

const SnippetView: React.FC = () => {
	const { snippetId } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const { getSnippet, activeSnippet, isLoadingStatus } = useSnippets({
		pollInterval: 0,
	});
	const [currentTime, setCurrentTime] = React.useState(0);
	const { data: user } = useMe();
	const [showAuthDialog, setShowAuthDialog] = useState(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);
	const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

	useEffect(() => {
		if (snippetId) {
			getSnippet(snippetId);
		}
	}, [snippetId]);

	const videoJsOptions = {
		controls: true,
		responsive: true,
		fluid: true,
		sources: activeSnippet?.video_url
			? [
					{
						src: activeSnippet.video_url,
						type: "video/mp4",
					},
				]
			: [],
	};

	const handleSeek = (time: number) => {
		if (playerRef.current) {
			playerRef.current.currentTime(time);
		}
	};

	const onCopy = () => {
		navigator.clipboard.writeText(window.location.href);
		setCopiedId(snippetId ?? null);
		setTimeout(() => setCopiedId(null), 2000);
	};

	if (isLoadingStatus) {
		return (
			<Container>
				<ContentWrapper>
					<HeaderSection>
						<Skeleton className="h-8 w-64" />
						<Skeleton className="h-6 w-96" />
					</HeaderSection>
					<Flex style={{ flex: 1, gap: 0 }}>
						<VideoSection>
							<Skeleton className="aspect-video w-full" />
						</VideoSection>
						<DetailsSection>
							<Skeleton className="h-full w-full" />
						</DetailsSection>
					</Flex>
				</ContentWrapper>
			</Container>
		);
	}

	if (!activeSnippet) {
		return (
			<Container>
				<Flex
					direction="column"
					align="center"
					justify="center"
					style={{ flex: 1 }}
				>
					<Icon.VideoCamera className="w-12 h-12 text-gray-400 mb-4" />
					<Text size="5" weight="medium">
						Snippet not found
					</Text>
				</Flex>
			</Container>
		);
	}

	if (activeSnippet.status.toLowerCase() !== "completed") {
		return (
			<Container>
				<Flex
					direction="column"
					align="center"
					justify="center"
					style={{ flex: 1 }}
				>
					<Icon.Spinner className="w-12 h-12 text-gray-400 mb-4 animate-spin" />
					<Text size="5" weight="medium">
						Processing video...
					</Text>
					<Text color="gray" className="mt-2">
						This may take a few minutes
					</Text>
				</Flex>
			</Container>
		);
	}

	return (
		<Container>
			<ContentWrapper>
				<HeaderSection>
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Text size="6" weight="medium">
							{activeSnippet.title}
						</Text>
						{!user && (
							<Flex gap="2" className="auth-buttons">
								<Button
									variant="white-bg"
									style={{
										height: "44px",
										borderRadius: "var(--radius-pill)",
										padding: "0px 24px",
									}}
									onClick={() => {
										localStorage.setItem(
											"intendedDestination",
											location.pathname + location.search,
										);
										navigate("/login");
									}}
								>
									Login
								</Button>
								<Button
									style={{
										height: "44px",
										borderRadius: "var(--radius-pill)",
										padding: "0px 24px",
									}}
									onClick={() => {
										localStorage.setItem(
											"intendedDestination",
											location.pathname + location.search,
										);
										navigate("/signup");
									}}
								>
									Sign up for free
								</Button>
							</Flex>
						)}
					</Flex>
				</HeaderSection>

				<MainContent>
					<VideoContainer>
						<Flex
							justify="between"
							align="center"
							style={{ gap: "10px", height: "34px" }}
						>
							<MetadataSection>
								<Icon.Clock className="w-4 h-4 text-gray-400" />
								<Text size="1" className="text-[13px]" color="gray">
									{Math.floor(activeSnippet.container_metadata.duration / 60)}m
								</Text>
								<Text className="color-[var(--mb-color-dark-gray-4)] text-[9px]">
									•{" "}
								</Text>
								<Icon.CalendarBlank className="w-4 h-4 text-gray-400" />
								<Text className="text-[13px]" color="gray">
									{new Date(activeSnippet.created_ts).toLocaleString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
										hour: "numeric",
										minute: "2-digit",
										hour12: true,
									})}
								</Text>
							</MetadataSection>
							{copiedId !== snippetId ? (
								<Icon.LinkSimple
									size="20px"
									weight="bold"
									className="cursor-pointer"
									onClick={onCopy}
								/>
							) : (
								<Icon.Check className="w-4 h-4 text-green-500" />
							)}
						</Flex>
						<VideoSection>
							<VideoJS
								options={videoJsOptions}
								setCurrentTime={setCurrentTime}
								ref={playerRef}
								onExpand={() => {}}
								startTime={activeSnippet.container_metadata.start_time}
								endTime={activeSnippet.container_metadata.end_time}
								src={activeSnippet.video_url}
								size="large"
							/>
						</VideoSection>
					</VideoContainer>

					<DetailsSection>
						<Tabs.Root defaultValue="transcript" style={{ height: "100%" }}>
							<Tabs.List
								className="h-[54px] flex-shrink-0"
								style={{
									borderBottom: "1px solid var(--gray-5)",
									backgroundColor: "white",
								}}
							>
								<Tabs.Trigger
									style={{
										fontSize: "16px",
										fontWeight: "500",
									}}
									className="h-full cursor-pointer"
									value="transcript"
								>
									Transcript
								</Tabs.Trigger>
								<Tabs.Trigger
									style={{
										fontSize: "16px",
										fontWeight: "500",
									}}
									className="h-full cursor-pointer"
									value="chat"
									onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
										if (!user) {
											e.preventDefault();
											setShowAuthDialog(true);
										}
									}}
								>
									Chat with Clip
								</Tabs.Trigger>
							</Tabs.List>

							<div
								style={{
									height: "calc(100% - 54px)",
									overflow: "hidden",
									display: "flex",
									flexDirection: "column",
									minHeight: 0,
								}}
							>
								<Tabs.Content
									value="transcript"
									className="h-full pl-[20px] pt-[10px] overflow-y-auto flex-1"
									style={{ minHeight: 0 }}
								>
									<TranscriptView
										currentTime={currentTime}
										onSeek={handleSeek}
										transcript={activeSnippet?.structured_text}
									/>
								</Tabs.Content>

								<Tabs.Content
									value="chat"
									className="h-full p-[20px] pr-0 overflow-y-auto flex-1"
									style={{ minHeight: 0 }}
								>
									<Text>Chat functionality coming soon...</Text>
								</Tabs.Content>
							</div>
						</Tabs.Root>
					</DetailsSection>
				</MainContent>
			</ContentWrapper>
			<UnauthenticatedDialog
				open={showAuthDialog}
				onOpenChange={setShowAuthDialog}
			/>
		</Container>
	);
};

export default SnippetView;
