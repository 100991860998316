import React, { useRef, useEffect } from "react";
import { useTranscriptSync } from "./useTranscriptSync";
import { cn } from "../../utils/classNames";
import { Icon, Button, Skeleton, AvatarWrapper } from "@mightybot/web-ui";
import { TranscriptSegment, useGetMeetingTranscript } from "@mightybot/core";
import { useParams } from "react-router-dom";
interface TranscriptViewProps {
	currentTime: number;
	onSeek: (time: number) => void;
	startTime?: number;
	endTime?: number;
	transcript?: TranscriptSegment[];
}

export const formatTimestamp = (
	seconds: number,
	shortFormat: boolean = false,
) => {
	if (seconds < 0) {
		return "00:00:00";
	}
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	if (hours > 0 || !shortFormat) {
		return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
	}
	// For short format, if minutes is less than 60, don't show hours
	return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const TranscriptView: React.FC<TranscriptViewProps> = ({
	currentTime,
	onSeek,
	startTime,
	endTime,
	transcript,
}) => {
	const meetingId = useParams().meetingId;
	const { data, isLoading: isLoadingTranscript } = useGetMeetingTranscript(
		{
			meeting_id: meetingId ?? "",
		},
		{ skip: transcript?.length !== undefined },
	);

	transcript = transcript ?? data?.transcript ?? [];

	const filteredTranscript = React.useMemo(() => {
		if (!startTime && !endTime) return transcript;

		return transcript.filter((segment) => {
			const segmentStart = segment.words[0].start_timestamp;
			const segmentEnd = segment.words[segment.words.length - 1].end_timestamp;
			return (
				(!startTime || segmentEnd >= startTime) &&
				(!endTime || segmentStart <= endTime)
			);
		});
	}, [transcript, startTime, endTime]);

	const { activeSegmentIndex } = useTranscriptSync(
		filteredTranscript,
		currentTime,
	);

	const activeSegmentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (activeSegmentRef.current) {
			const container = activeSegmentRef.current.parentElement;
			if (container) {
				const elementRect = activeSegmentRef.current.getBoundingClientRect();
				const containerRect = container.getBoundingClientRect();

				const relativeTop = elementRect.top - containerRect.top;
				const centerOffset = (containerRect.height - elementRect.height) / 2;

				container.scrollBy({
					top: relativeTop - centerOffset,
					behavior: "smooth",
				});
			}
		}
	}, [activeSegmentIndex]);

	if (isLoadingTranscript) {
		return (
			<div className="h-full flex flex-col overflow-hidden">
				<div className="flex-1 overflow-y-auto px-4 mb-[100px]">
					{[1, 2, 3, 4].map((_, index) => (
						<div key={index} className="mb-6">
							<div className="flex items-center gap-2 mb-2">
								<Skeleton className="h-6 w-6 rounded-full" />
								<Skeleton className="h-4 w-24" />
								<Skeleton className="h-4 w-16" />
							</div>
							<div className="space-y-2">
								<Skeleton className="h-4 w-full" />
								<Skeleton className="h-4 w-3/4" />
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<div className="h-full flex flex-col overflow-hidden">
			<div className="flex-1 overflow-y-auto">
				{filteredTranscript?.map((segment, index) => (
					<div
						key={`${segment.speaker_id}-${index}`}
						ref={index === activeSegmentIndex ? activeSegmentRef : undefined}
						className="mb-2"
					>
						<div className="flex items-center gap-1 mb-1">
							<AvatarWrapper
								size={"1"}
								radius="full"
								src={""}
								alt={segment.speaker}
							/>
							<div className="font-medium text-text-primary text-[13px]">
								{segment.speaker}
							</div>
							<Button
								size="1"
								onClick={() => {
									onSeek(segment.words[0].start_timestamp);
								}}
								color="gray"
								variant="ghost"
								className="!mx-[5px] cursor-pointer !p-1"
							>
								<Icon.Play className="h-4 w-4 text-text-primary" />
								{formatTimestamp(segment.words[0].start_timestamp)}
							</Button>
						</div>
						<div className="leading-relaxed">
							{segment.words.map((word, wordIndex) => (
								<span
									key={wordIndex}
									className={cn(
										"inline-block px-1 py-0.5 rounded transition-colors duration-200 cursor-pointer",
										index === activeSegmentIndex
											? "bg-[#CFDFFF]"
											: "hover:bg-gray-100",
									)}
									onClick={() => onSeek(word.start_timestamp)}
								>
									{word.text}
								</span>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default TranscriptView;
