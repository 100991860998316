import React, { useEffect, useState } from "react";
import {
	GoToEndButton,
	GoToEndButtonContainer,
	MeetingContentContainer,
} from "./styled";
import MeetingContentScroller from "./MeetingContentScroller";
import MeetingContentPrompt from "./MeetingContentPrompt";
import { Flex, Icon, Tabs, useMediaQuery } from "@mightybot/web-ui";
import { MeetingChatProvider } from "../../contexts/MeetingChatContext";
import { MeetingContentHoverProvider } from "../../contexts/MeetingContentHoverContext";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { useParams } from "react-router-dom";
import MeetingTranscriptSidebar from "../MeetingTranscriptSidebar";
import { ToggleButton } from "../MeetingTranscriptSidebar/MeetingTranscriptSidebar";
import { cn } from "../../utils/classNames";
import { scrollToElement } from "../../utils/helpers";
import { breakpoints } from "@mightybot/web-ui";
import SharedClips from "../SharedClips";

interface MeetingContentProps {
	isSidebarOpen: boolean;
}

const MeetingContent: React.FC<MeetingContentProps> = ({ isSidebarOpen }) => {
	const { view } = useParams();
	const [showGoToEnd, setShowGoToEnd] = useState(false);
	const { containerRef, endOfContentRef, scrollerRef } =
		useMeetingContentNavigation();
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [isTranscriptSidebarOpen, setIsTranscriptSidebarOpen] = useState(
		isTablet ? false : true,
	);

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
				const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100; // 100px threshold

				setShowGoToEnd(!isAtBottom);
			}
		};

		const container = containerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
			window.addEventListener("resize", handleScroll);

			return () => {
				container.removeEventListener("scroll", handleScroll);
				window.removeEventListener("resize", handleScroll);
			};
		}
	}, [containerRef]);

	const scrollToEnd = () => {
		if (containerRef.current && endOfContentRef.current) {
			scrollToElement(containerRef.current, endOfContentRef.current);
		}
	};

	const toggleTranscriptSidebar = () => {
		setIsTranscriptSidebarOpen(!isTranscriptSidebarOpen);
	};

	return (
		<MeetingChatProvider>
			<MeetingContentHoverProvider>
				<div
					className={cn(
						"h-full overflow-hidden flex flex-col w-full pb-[10px] transition-all duration-300",
						isSidebarOpen &&
							!isTablet &&
							"border-x-1 border-border-primary border-solid border-y-0",
						isTablet && "pb-0",
						isTranscriptSidebarOpen && !isTablet && "mr-[350px]",
					)}
				>
					<Tabs.Root defaultValue="summary" className="h-full flex flex-col">
						<Tabs.List
							size="1"
							className={cn(
								"h-[54px] flex-shrink-0 relative",
								isTablet && "overflow-x-auto h-[44px]",
							)}
						>
							<Flex style={{ flex: 1 }}>
								<Tabs.Trigger
									value="summary"
									className="whitespace-nowrap h-full cursor-pointer"
								>
									<div
										className={cn(
											"flex items-center gap-2 text-lg",
											isTablet && "text-base",
										)}
									>
										<Icon.FileText size={20} />
										Summary
									</div>
								</Tabs.Trigger>

								<Tabs.Trigger
									value="shared_clips"
									className="whitespace-nowrap h-full cursor-pointer"
								>
									<div
										className={cn(
											"flex items-center gap-2 text-lg",
											isTablet && "text-base",
										)}
									>
										<Icon.Crop size={20} />
										Shared Clips
									</div>
								</Tabs.Trigger>

								{isTablet && (
									<Tabs.Trigger
										value="transcript"
										className="whitespace-nowrap h-full cursor-pointer"
									>
										<div
											className={cn(
												"flex items-center gap-2 text-lg",
												isTablet && "text-base",
											)}
										>
											<Icon.ListBullets size={20} />
											Transcript
										</div>
									</Tabs.Trigger>
								)}
							</Flex>

							<div
								className={cn(
									"absolute right-0 -translate-y-1/2",
									isTablet && "hidden",
								)}
							>
								<ToggleButton
									onClick={toggleTranscriptSidebar}
									isExpanded={isTranscriptSidebarOpen}
								/>
							</div>
						</Tabs.List>

						<Tabs.Content
							value="summary"
							className={cn("flex-1 overflow-hidden")}
						>
							<MeetingContentContainer ref={containerRef}>
								<Flex
									direction="column"
									ref={scrollerRef}
									style={{ width: "100%", flex: 1, paddingBottom: "30px" }}
								>
									<MeetingContentScroller
										endOfContentRef={endOfContentRef}
										isTranscriptSidebarOpen={isTranscriptSidebarOpen}
									/>
								</Flex>
								<Flex
									direction="column"
									style={{
										width: "100%",
										position: "sticky",
										bottom: isTablet ? 0 : 60,
										zIndex: 999,
									}}
								>
									<GoToEndButtonContainer
										className={showGoToEnd ? "visible" : ""}
									>
										<GoToEndButton onClick={scrollToEnd}>
											<Icon.ArrowDown
												fill="#333"
												style={{
													width: "20px",
													height: "20px",
													flexShrink: 0,
												}}
											/>
										</GoToEndButton>
									</GoToEndButtonContainer>
									{view === "details" && <MeetingContentPrompt />}
								</Flex>
							</MeetingContentContainer>
						</Tabs.Content>

						<Tabs.Content value="shared_clips" className="overflow-hidden">
							<SharedClips />
						</Tabs.Content>

						<Tabs.Content value="transcript" className="overflow-hidden">
							<MeetingTranscriptSidebar
								isOpen={true}
								className={cn(
									"h-full transition-transform duration-300",
									!isTranscriptSidebarOpen && "translate-x-full",
								)}
							/>
						</Tabs.Content>
					</Tabs.Root>
				</div>
			</MeetingContentHoverProvider>
			{!isTablet && (
				<MeetingTranscriptSidebar
					isOpen={isTranscriptSidebarOpen}
					className={cn(
						"fixed right-0 h-full transition-transform duration-300",
						!isTranscriptSidebarOpen && "translate-x-full",
						isTablet && "z-1001",
					)}
				/>
			)}
		</MeetingChatProvider>
	);
};

export default MeetingContent;
