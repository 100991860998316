import React, { useState, useRef, useEffect } from "react";
import {
	Dialog,
	Button,
	Flex,
	Text,
	Icon,
	Input,
	TextArea,
	breakpoints,
	useMediaQuery,
	Skeleton,
	Slider,
} from "@mightybot/web-ui";
import VideoJS from "../VideoPlayer/VideoJsPlayer";
import TranscriptView from "../VideoPlayer/TranscriptView";
import { formatTimestamp } from "../VideoPlayer/TranscriptView";
import {
	DialogContent,
	TranscriptContainer,
	ClipDetailsSection,
	ClipFormSection,
	ContentWrapper,
	DialogRoot,
} from "./styled";
import videojs from "video.js";
import { GeneralAccess } from "../Share/GeneralAccess";
import { useParams } from "react-router-dom";
import { InvitePeople } from "../Share/InvitePeople";
import {
	AccessType,
	useGetMeetingVideoQuery,
	useMeeting,
	User,
	useSnippets,
} from "@mightybot/core";
import { formatDuration } from "../../utils/helpers";
import Toast from "../Toast";

interface ShareClipProps {
	onClose: () => void;
}

const TimeMarkers = ({ duration }: { duration: number }) => {
	const markers = [];
	const interval = 5 * 60; // 5 minutes in seconds

	for (let time = 0; time <= duration; time += interval) {
		markers.push(
			<div
				key={time}
				style={{
					position: "absolute",
					left: `${(time / duration) * 100}%`,
					transform: "translateX(-50%)",
				}}
			>
				<Text size="1" style={{ whiteSpace: "nowrap" }}>
					{formatTimestamp(time, true)}
				</Text>
			</div>,
		);
	}
	return <div style={{ position: "relative" }}>{markers}</div>;
};

const ShareClip: React.FC<ShareClipProps> = ({ onClose }) => {
	const meetingId = useParams().meetingId;
	const {
		data,
		isLoading: isLoadingVideo,
		refetch,
	} = useGetMeetingVideoQuery({
		meeting_id: meetingId ?? "",
	});
	const { meeting, isLoading } = useMeeting(meetingId ?? "");

	const duration: number =
		(formatDuration(
			meeting?.start_time ?? "",
			meeting?.end_time ?? "",
			true,
		) as number) * 60;
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState<number>(duration ?? 0);
	const [currentTime, setCurrentTime] = useState(0);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);
	const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

	const {
		createSnippet,
		isCreating: isCreatingSnippet,
		activeSnippet,
		refetchSnippets,
		isPending,
	} = useSnippets();

	const [startTimeInput, setStartTimeInput] = useState(formatTimestamp(0));
	const [endTimeInput, setEndTimeInput] = useState(
		formatTimestamp(duration ?? 0),
	);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [toastOpen, setToastOpen] = useState(false);

	const videoJsOptions = {
		controls: true,
		responsive: true,
		fluid: true,
		sources: [
			{
				src: data?.video_url ?? "",
				type: "video/mp4",
			},
		],
	};

	const handleSeek = (time: number) => {
		if (playerRef.current) {
			playerRef.current.currentTime(time);
		}
	};

	const onDialogClose = () => {
		setDialogOpen(false);
		setToastOpen(false);
		setTitle("");
		setDescription("");
		setStartTime(0);
		setEndTime(duration ?? 0);
		onClose();
	};

	const handleSubmit = async () => {
		try {
			const response = await createSnippet({
				video_url: data?.video_url ?? "",
				start_time: startTime,
				end_time: endTime,
				clip_title: title,
				clip_description: description,
				source_collection_id: meetingId ?? "",
			});
			if (response.status === "accepted") {
				setToastOpen(true);
				setTitle("");
				setDescription("");
				setStartTime(0);
				setEndTime(duration ?? 0);
				await refetchSnippets();
				setDialogOpen(false);
				onClose();
			}
		} catch (error) {
			console.error("Failed to create clip:", error);
		}
	};

	const handleStartTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.target.value;
		setStartTimeInput(input);

		// Allow typing without immediate validation
		if (/^[\d:]*$/.test(input)) {
			try {
				const timeArray = input.split(":");
				let seconds = 0;

				if (timeArray.length === 2) {
					const [minutes, secs] = timeArray.map(Number);
					if (!isNaN(minutes) && !isNaN(secs) && secs < 60) {
						seconds = minutes * 60 + secs;
					}
				} else if (timeArray.length === 3) {
					const [hours, minutes, secs] = timeArray.map(Number);
					if (
						!isNaN(hours) &&
						!isNaN(minutes) &&
						!isNaN(secs) &&
						minutes < 60 &&
						secs < 60
					) {
						seconds = hours * 3600 + minutes * 60 + secs;
					}
				}

				// Only update if within valid range
				if (!isNaN(seconds) && seconds >= 0 && seconds < endTime) {
					setStartTime(seconds);
					if (playerRef.current) {
						playerRef.current.currentTime(seconds);
					}
				}
			} catch (error) {
				// Invalid format - do nothing
			}
		}
	};

	const handleEndTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const input = e.target.value;
		setEndTimeInput(input);

		// Allow typing without immediate validation
		if (/^[\d:]*$/.test(input)) {
			try {
				const timeArray = input.split(":");
				let seconds = 0;

				if (timeArray.length === 2) {
					const [minutes, secs] = timeArray.map(Number);
					if (!isNaN(minutes) && !isNaN(secs) && secs < 60) {
						seconds = minutes * 60 + secs;
					}
				} else if (timeArray.length === 3) {
					const [hours, minutes, secs] = timeArray.map(Number);
					if (
						!isNaN(hours) &&
						!isNaN(minutes) &&
						!isNaN(secs) &&
						minutes < 60 &&
						secs < 60
					) {
						seconds = hours * 3600 + minutes * 60 + secs;
					}
				}

				// Only update if within valid range
				if (!isNaN(seconds) && seconds > startTime && seconds <= duration) {
					setEndTime(seconds);
					if (playerRef.current) {
						playerRef.current.currentTime(seconds);
					}
				}
			} catch (error) {
				// Invalid format - do nothing
			}
		}
	};

	useEffect(() => {
		setStartTimeInput(formatTimestamp(startTime));
	}, [startTime]);

	useEffect(() => {
		setEndTimeInput(formatTimestamp(endTime));
	}, [endTime]);

	if (isLoading || isLoadingVideo) {
		return (
			<Dialog.Root>
				<Dialog.Trigger>
					<Button variant="outline" radius="full">
						<Icon.Crop />
						Create New Clip
					</Button>
				</Dialog.Trigger>

				<Dialog.Content style={{ padding: "0px", maxWidth: "970px" }}>
					<Flex
						gap="4"
						align="center"
						style={{
							borderBottom: "1px solid var(--gray-5)",
							padding: "0px 10px 0px 20px",
							height: "60px",
						}}
					>
						<Dialog.Title style={{ marginBottom: "0px" }}>
							Create Share Clip
						</Dialog.Title>
						<Dialog.Close onClick={onDialogClose}>
							<Icon.X style={{ marginLeft: "auto", cursor: "pointer" }} />
						</Dialog.Close>
					</Flex>

					<DialogContent>
						<Flex style={{ width: "100%", flex: 1 }}>
							<ClipFormSection>
								<Skeleton className="aspect-video rounded-md w-full" />
								<Flex justify="between" style={{ padding: "8px 0" }}>
									<Skeleton className="h-10 w-[120px]" />
									<Skeleton className="h-10 w-[120px]" />
								</Flex>
								<Skeleton className="h-8 w-full" />
								<Skeleton className="h-[200px] w-full" />
							</ClipFormSection>

							<ClipDetailsSection justify="between">
								<Flex direction="column" gap="4">
									<Flex direction="column" gap="2">
										<Skeleton className="h-6 w-24" />
										<Skeleton className="h-10 w-full" />
									</Flex>
									<Flex direction="column" gap="2">
										<Skeleton className="h-6 w-32" />
										<Skeleton className="h-24 w-full" />
									</Flex>
									<Flex direction="column" gap="2">
										<Skeleton className="h-6 w-24" />
										<Skeleton className="h-6 w-16" />
									</Flex>
								</Flex>
								<Flex justify="between" gap="3">
									<Skeleton className="h-10 w-24" />
									<Flex gap="3">
										<Skeleton className="h-10 w-20" />
										<Skeleton className="h-10 w-32" />
									</Flex>
								</Flex>
							</ClipDetailsSection>
						</Flex>
					</DialogContent>
				</Dialog.Content>
			</Dialog.Root>
		);
	}

	return (
		<>
			<DialogRoot open={dialogOpen} onOpenChange={setDialogOpen}>
				<Dialog.Trigger>
					<Button variant="outline" radius="full">
						<Icon.Crop />
						Create New Clip
					</Button>
				</Dialog.Trigger>

				<Dialog.Content
					style={{
						padding: "0px",
						maxWidth: "970px",
						margin: isMobile ? "0" : "auto",
						borderRadius: isMobile ? "0" : "12px",
						height: isMobile ? "100vh" : undefined,
						width: isMobile ? "100vw" : undefined,
						position: isMobile ? "fixed" : undefined,
						top: isMobile ? "0" : undefined,
						left: isMobile ? "0" : undefined,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Flex
						gap="4"
						align="center"
						style={{
							borderBottom: "1px solid var(--gray-5)",
							padding: "0px 10px 0px 20px",
							height: "60px",
						}}
					>
						<Dialog.Title
							style={{
								marginBottom: "0px",
								fontSize: "18px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
						>
							Create Share Clip
						</Dialog.Title>
						<Dialog.Close onClick={onDialogClose}>
							<Icon.X style={{ marginLeft: "auto", cursor: "pointer" }} />
						</Dialog.Close>
					</Flex>

					<DialogContent>
						<ContentWrapper>
							<ClipFormSection>
								<VideoJS
									options={videoJsOptions}
									onReady={(player) => {
										playerRef.current = player;
									}}
									setCurrentTime={setCurrentTime}
									onExpand={() => {}}
									ref={playerRef}
									src={data?.video_url}
									startTime={startTime}
									endTime={endTime}
									size="large"
								/>

								<Flex justify="between" style={{ padding: "8px 0" }}>
									<Flex align="center" gap="2">
										<Text size="2">Start:</Text>
										<Input
											value={startTimeInput}
											onChange={handleStartTimeInput}
											style={{ width: isTablet ? "100%" : "100px" }}
											placeholder="00:00:00"
										/>
									</Flex>
									<Flex align="center" gap="2">
										<Text size="2">End:</Text>
										<Input
											value={endTimeInput}
											onChange={handleEndTimeInput}
											style={{ width: isTablet ? "100%" : "100px" }}
											placeholder="00:00:00"
										/>
									</Flex>
								</Flex>

								<Flex direction="column" gap="5">
									<TimeMarkers duration={duration} />
									<Slider
										value={[startTime, endTime]}
										min={0}
										max={duration}
										step={1}
										onValueChange={([newStart, newEnd]) => {
											setStartTime(newStart);
											setEndTime(newEnd);
											if (playerRef.current) {
												playerRef.current.currentTime(newStart);
											}
										}}
									/>
								</Flex>

								<TranscriptContainer>
									<TranscriptView
										currentTime={currentTime}
										onSeek={handleSeek}
										startTime={startTime}
										endTime={endTime}
									/>
								</TranscriptContainer>
							</ClipFormSection>

							<ClipDetailsSection justify="between">
								<Flex direction="column" gap="4">
									<Flex direction="column" gap="2">
										<Text size="2" weight="medium" className="mb-2">
											Title
										</Text>
										<Input
											value={title}
											onChange={(e) => setTitle(e.target.value)}
											placeholder="Enter clip title"
										/>
									</Flex>

									<Flex direction="column" gap="2">
										<Text size="2" weight="medium" className="mb-2">
											Description (Optional)
										</Text>
										<TextArea
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											placeholder="Add a description for your clip"
											rows={4}
										/>
									</Flex>

									<Flex direction="column" gap="2">
										<Text size="2" weight="medium" className="mb-2">
											Duration
										</Text>
										<Text color="gray">
											{formatTimestamp(Math.max(0, endTime - startTime), true)}
										</Text>
									</Flex>
									{/* <GeneralAccess access={access} onRadioChange={onRadioChange} /> */}
									{/* <InvitePeople
										shareData={shareData}
										handleRemove={handleRemove}
										sharedUsers={sharedUsers?.users ?? []}
										searchContainerRef={searchContainerRef}
										searchQuery={searchQuery}
										searchResultsOpen={searchResultsOpen}
										inputRef={inputRef}
										searchResultsRef={searchResultsRef}
										handleSearchChange={handleSearchChange}
										handleInvite={handleInvite}
										isSharing={isSharing}
										onOpenChange={onOpenChange}
									/> */}
								</Flex>
								<Flex
									justify="between"
									gap="3"
									direction={isMobile ? "column" : "row"}
									style={{
										marginTop: isMobile ? "auto" : undefined,
										width: "100%",
									}}
								>
									<Dialog.Close>
										<Button
											variant="outline"
											style={{ width: isMobile ? "100%" : undefined }}
										>
											Copy Link
										</Button>
									</Dialog.Close>
									<Flex
										gap="3"
										direction={isMobile ? "column" : "row"}
										style={{ width: isMobile ? "100%" : undefined }}
									>
										<Dialog.Close>
											<Button
												variant="soft"
												color="gray"
												style={{ width: isMobile ? "100%" : undefined }}
												onClick={onDialogClose}
											>
												Cancel
											</Button>
										</Dialog.Close>
										<Button
											disabled={!title}
											onClick={handleSubmit}
											loading={isCreatingSnippet || isPending}
											style={{ width: isMobile ? "100%" : undefined }}
										>
											{isPending ? "Processing..." : "Create"}
										</Button>
									</Flex>
								</Flex>
							</ClipDetailsSection>
						</ContentWrapper>
					</DialogContent>
				</Dialog.Content>
			</DialogRoot>

			<Toast
				open={toastOpen}
				setOpen={setToastOpen}
				title="Success"
				description="Clip created successfully!"
				type="success"
			/>
		</>
	);
};

export default ShareClip;
