import styled from "styled-components";
import { Flex, mediaQueries } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	flex-direction: column;
	width: 100%;
	background: white;
	padding: 24px;

	${mediaQueries.tablet} {
		padding: 16px;
	}

	${mediaQueries.mobile} {
		padding: 12px;
	}
`;

export const Header = styled(Flex)`
	padding: 12px 16px;
	background: #f9fafb;
	border: 1px solid #e5e7eb;
	border-radius: 8px 8px 0 0;
	align-items: center;
	justify-content: space-between;
	${mediaQueries.tablet} {
		gap: 16px;
	}
`;

export const ClipRow = styled(Flex)`
	padding: 12px 16px;
	border: 1px solid #e5e7eb;
	border-top: none;
	gap: 16px;

	&:last-child {
		border-radius: 0 0 8px 8px;
	}

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;
		padding: 16px;

		.clip-metadata {
			align-items: flex-start;
			gap: 8px;
		}
	}

	${mediaQueries.mobile} {
		padding: 12px;

		.clip-actions {
			width: 100%;
			justify-content: flex-start;
			gap: 8px;
		}
	}
`;

export const EmptyState = styled(Flex)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 48px;
	text-align: center;
	background: #f9fafb;
	border-radius: 8px;
	border: 1px dashed #e5e7eb;
	gap: 16px;

	${mediaQueries.tablet} {
		padding: 32px 16px;
	}

	${mediaQueries.mobile} {
		padding: 24px 12px;
	}
`;
